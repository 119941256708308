import React, { useState, useEffect, useContext } from "react";
import uuid from "react-uuid";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faExclamationTriangle,
  faSpinner,
  faDharmachakra,
  faCaretRight,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

import "./VenueList.scss";

import DayTime from "../../components/daytime/DayTime";
import ExtraInfoItem from "./ExtraInfoItem";
import UrlContext from "../../urlContext";
import RemarkItem from "./RemarkItem";
import Location from "./Location";
import Moment from "moment";

const Remarks = (props) => {
  //{venue.dataVerifiedByAdmin === false && <RemarkItem type="verified" data={venue.dataVerifiedByAdmin} />}
  const { venue, relocated, cancelled } = props;
  return (
    <div className="remarks-container">
      {cancelled && <RemarkItem type="cancellation" />}
      {relocated && <RemarkItem type="relocation" data={venue.cancellations} />}
    </div>
  );
};

const VenueItem = (props) => {
  const { t } = useTranslation();
  const { venue, type } = props;
  const { context } = venue;

  const [isCancelled, setIsCancelled] = useState(false);
  const [cancellationReason, setCancellationReason] = useState(null);
  const [isRelocated, setIsRelocated] = useState(false);
  const [relocationReason, setRelocationReason] = useState(null);

  const setLastActivePath = props.pathData[1];
  const history = useHistory();
  const location = useLocation();

  const TimeConverter = (time) => {
    return Moment(new Date(time)).format("DD/MM/YYYY");
  };

  const renderTime = () => {
    if (venue.firstUpcomingEvent) {
      return (
        <span
          className="fair-date"
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            padding: "0.5rem 0",
          }}
        >
          <FontAwesomeIcon
            icon={faCalendarDay}
            className="4x back-icon"
            style={{ marginRight: "0.2rem" }}
          />
          <span className="fair-date-text" style={{ lineHeight: 0 }}>
            {venue.firstUpcomingEvent.start === venue.firstUpcomingEvent.end
              ? TimeConverter(venue.firstUpcomingEvent.start)
              : `${TimeConverter(
                  venue.firstUpcomingEvent.start
                )} - ${TimeConverter(venue.firstUpcomingEvent.end)}`}
          </span>
        </span>
      );
    } else {
      return null;
    }
  };

  const setActiveCircumstances = () => {
    if (venue.firstUpcomingEvent) {
      const eventStartDate = Date.parse(venue.firstUpcomingEvent.start);

      const setActiveCircumstance = (arr, stateFn, stateReasonFn) => {
        if (arr && arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (
              Date.parse(arr[i].startDate) <= eventStartDate &&
              eventStartDate <= Date.parse(arr[i].endDate)
            ) {
              stateFn(() => {
                return true;
              });

              stateReasonFn(() => {
                return arr[i].reason;
              });
              break;
            }
          }
        }
      };
      setActiveCircumstance(
        venue.cancellations,
        setIsCancelled,
        setCancellationReason
      );
      setActiveCircumstance(
        venue.relocations,
        setIsRelocated,
        setRelocationReason
      );
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted === true) {
      setActiveCircumstances();
    }

    return () => (mounted = false);
  });

  const renderLocation = () => {
    const locations = venue.location.split(/[;,]/);
    const originalLocations = locations.map((myLocation) => (
      <Location
        key={uuid()}
        location={myLocation}
        moved={isRelocated}
        context={venue.context}
      />
    ));

    let relocations = null;

    if (isRelocated) {
      relocations = venue.relocations.map((relocation) => (
        <Location
          key={uuid()}
          location={relocation.newLocation}
          moved={!isRelocated}
          context={venue.context}
        />
      ));
    }
    return (
      <React.Fragment>
        {originalLocations}
        {relocations}
      </React.Fragment>
    );
  };

  const itemClickHandler = (type, id) => {
    if (location.search) {
      setLastActivePath(location.pathname + location.search);
    } else {
      setLastActivePath(location.pathname);
    }
    history.push(`/${type === "MARKET" ? "market" : "fair"}/${id}`);
  };

  return (
    <div
      className={`venue-list-item ${
        type === "MARKET" ? "market-item" : "fair-item"
      }`}
    >
      <div className="info-container-placeholder">
        <div className="info-container" style={{ display: "flex" }}>
          <span className="venue-icon">
            {type === "MARKET" ? (
              <FontAwesomeIcon icon={faStore} />
            ) : (
              <FontAwesomeIcon icon={faDharmachakra} />
            )}
          </span>
          <span className="info-data">
            <span className="context-name">{context.name}</span>
            <span className="venue-name">{venue.name}</span>
            {venue !== null && <DayTime venue={venue} />}
            {venue.location && renderLocation()}
            {renderTime()}
            <div className="more-info">
              <span
                className="more-info-text"
                onClick={(e) => itemClickHandler(type, venue.id)}
              >
                {t("moreInfo")}
                <FontAwesomeIcon icon={faCaretRight} size="2x" />
              </span>
            </div>
          </span>
        </div>

        <Remarks
          venue={venue}
          cancelled={isCancelled}
          relocated={isRelocated}
        />
        <div className="extra-info-container">
          {isCancelled && cancellationReason && (
            <ExtraInfoItem type="cancellation" reason={cancellationReason} />
          )}
          {isRelocated && relocationReason && (
            <ExtraInfoItem type="relocation" reason={relocationReason} />
          )}
          {!venue.offer &&
            !venue.website &&
            !venue.plandId &&
            !cancellationReason &&
            !relocationReason &&
            t("noExtraInfo")}
          {venue.offer && <ExtraInfoItem type="offer" offer={venue.offer} />}
          {venue.website && (
            <ExtraInfoItem type="website" website={venue.website} />
          )}
          {venue.planId && <ExtraInfoItem type="plan" planId={venue.planId} />}
        </div>
      </div>
    </div>
  );
};

const VenueList = (props) => {
  const { t } = useTranslation();
  const { venues, setVenues } = props;
  const { baseUrl, uniqueId } = useContext(UrlContext);
  const [online, setIsOnline] = useState(true);
  const [noZipCodes, setNoZipCodes] = useState(true);
  const [reqInProgress, setReqInProgress] = useState(false);

  const checkConnection = () => {
    if (window.navigator.onLine) {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  };

  let list = [];
  if (venues !== undefined) {
    if (props.type === "MARKET" || props.type === "FAIR") {
      list = venues
        .sort((venueItemA, venueItemB) =>
          venueItemA.name > venueItemB.name ? 1 : -1
        )
        .sort((venueItemA, venueItemB) =>
          venueItemA.firstUpcomingEvent === undefined ||
          venueItemA.firstUpcomingEvent === null
            ? 1
            : venueItemB.firstUpcomingEvent === undefined ||
              venueItemB.firstUpcomingEvent === null
            ? -1
            : venueItemA.firstUpcomingEvent.start >
              venueItemB.firstUpcomingEvent.start
            ? 1
            : -1
        )
        .filter((venueItem) => venueItem.venueType === props.type)
        //only show if firstupcoming
        .filter((venueItem) => {
          return venueItem.firstUpcomingEvent;
        })
        .map((venueItem) => (
          <VenueItem
            key={venueItem.id}
            type={venueItem.venueType}
            pathData={props.pathData}
            venue={venueItem}
          />
        ));
    } else {
      list = venues.map((venueItem) => (
        <VenueItem
          key={venueItem.id}
          type={venueItem.venueType}
          venue={venueItem}
          pathData={props.pathData}
        />
      ));
    }
  }

  useEffect(() => {
    let mounted = true;
    checkConnection();
    if (online) {
      var url = new URL(`${baseUrl}/venues`);

      const options = {
        method: "GET",
        headers: new Headers({ Accept: "application/json" }),
      };

      var paramsString = new URLSearchParams();

      let params = [
        { property: "opened", value: uniqueId },
        { property: "getUpcomingRelocations", value: true },
        { property: "getUpcomingCancellations", value: true },
        { property: "getFirstUpcomingEvent", value: true },
      ];

      if (props.searchValue) {
        setNoZipCodes(false);
        params.push({ property: "foriSearch", value: props.searchValue });
        setReqInProgress(true);
        paramsString.append("filter", JSON.stringify(params));
        fetch(url + "?" + paramsString, options)
          .then((response) => response.json())
          .then((data) => {
            if (mounted === true) {
              setReqInProgress(false);
              setVenues(() => data.data);
            }
          });
      } else if (
        typeof props.zipcodes !== "undefined" &&
        props.zipcodes !== null &&
        props.zipcodes.length > 0 &&
        mounted === true
      ) {
        setNoZipCodes(() => false);
        const zipcodes = props.zipcodes.map((zipcodeObj) =>
          zipcodeObj.zipcode.toString()
        );
        params.push({ property: "zipcodes", value: zipcodes });
        paramsString.append("filter", JSON.stringify(params));
        if (mounted === true) {
          setReqInProgress(true);
          fetch(url + "?" + paramsString, options)
            .then((response) => response.json())
            .then((data) => {
              if (mounted === true) {
                setReqInProgress(false);
                setVenues(() => data.data);
              }
            });
        }
      } else if (
        typeof props.zipcodes !== "undefined" &&
        props.zipcodes.length === 0 &&
        mounted === true
      ) {
        setNoZipCodes(() => true);
        setVenues([]);
      }
    }

    return () => {
      mounted = false;
    };
  }, [props.searchValue, props.zipcodes, baseUrl, online, setVenues, uniqueId]);

  if (online) {
    if (list.length > 0) {
      let cls = "";

      if (props.type) {
        if (props.type === "MARKET") {
          cls = "market-list";
        } else {
          cls = "fair-list";
        }
      }
      return <div className={`venue-list ${cls}`}>{list}</div>;
    } else {
      let loadItemText = t("loading");
      switch (props.type) {
        case "MARKET":
          loadItemText = t("loadingMarkets");
          break;
        case "FAIR":
          loadItemText = t("loadingFairs");
          break;
        default:
          break;
      }

      let noResultText = t("noResults");

      if (props.noresulttext) {
        noResultText = props.noresulttext;
      }

      if (reqInProgress) {
        return (
          <div className="msg msg-loading">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin msg-icon"
              size="2x"
            />
            <span className="msg-text">{loadItemText}</span>
          </div>
        );
      } else {
        if (noZipCodes) {
          return (
            <div className="msg msg-warning">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="msg-icon"
                size="2x"
              />
              <span className="msg-text">
                <Link
                  to="/settings/zipcodes"
                  dangerouslySetInnerHTML={{ __html: t("noZip") }}
                ></Link>
              </span>
            </div>
          );
        } else {
          return (
            <div className="msg msg-warning">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="msg-icon"
                size="2x"
              />
              <span className="msg-text">{noResultText}</span>
            </div>
          );
        }
      }
    }
  } else {
    return (
      <div className="offline-msg">
        {t("offlineText")}
        <br />
        {t("offlineText2")}
        <br />
        <button onClick={checkConnection}>{t("offlineCheck")}</button>
      </div>
    );
  }
};

export default VenueList;
