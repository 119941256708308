import React, {useState} from 'react';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import './Languages.scss';

const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem("defaultLanguage", lang);
};

const LanguageButton = (props) => {
    const {t} = useTranslation();
    return <button className={`language-button ${window.localStorage.getItem("defaultLanguage") === props.abbreviation ? 'selected' : ''}`} onClick={e => changeLanguage(props.abbreviation)}>{t("languages." + props.language)}</button>;
};

const Languages = () => {
    
    const [selectedLanguage] = useState(window.localStorage.getItem("defaultLanguage"));
    
    return (
            <div>
                <LanguageButton language="dutch" abbreviation="nl" currentLang={selectedLanguage}/>
                <LanguageButton language="french" abbreviation="fr" currentLang={selectedLanguage}/>
                <LanguageButton language="german" abbreviation="de" currentLang={selectedLanguage}/>
                <LanguageButton language="english" abbreviation="en" currentLang={selectedLanguage}/>   
            </div>);
};

export default Languages;