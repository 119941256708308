import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import logo from '../forilogo.svg'
import './Header.scss'
import { useHistory, useLocation} from "react-router-dom"

const Header = (props) => {
    const location = useLocation();
    const history = useHistory();
    const setLastActivePath = props.pathData[1];
    
    const handleClick = () => {
        setLastActivePath(location.pathname);
        history.push('/settings');
    };
    
    return (
        <header className="header">
            <img src={logo} alt="Fori logo" />
            <div className="icon settings-icon">
                    <FontAwesomeIcon icon={faCog} onClick={handleClick}/>
            </div>
        </header>
   );
};

export default Header