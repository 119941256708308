import React from "react"
import { Link, Switch, Route, useHistory } from "react-router-dom";
import { Motion, spring } from "@serprex/react-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Helmet} from "react-helmet";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import './Settings.scss'

import CloseIcon from '../closeicon/CloseIcon';
import ZipCodes from '../../containers/ZipCodes';
import Languages from '../../containers/Languages';
import Legal from '../../containers/Legal';

const SettingsItem = (props) => {
    const {t} = useTranslation();
    return (
            <li><Link to={`/settings/${props.route}`}>{t(props.titel)}</Link></li>
            );
};

const SettingsMenu = (props) => {
    return (
            <ul className="settings-list">
                <SettingsItem titel="zipcodes" route="zipcodes" setzipcodes={props.setzipcodes}/>
                <SettingsItem titel="legal" route="legal"/>
                <SettingsItem titel="myLang" route="language"/>
            </ul>
            );
};

const MotionContainer = (props) => {
    return (
            <Motion
                defaultStyle={{opacity: 0, left: -50}}
                style={{
                        opacity: spring(1, {stiffness: 105, damping: 25}),
                        left: spring(0, {stiffness: 300, damping: 50})
                    }}
                >
                {interpolatesStyles => (
                                <div className="animation-frame" style={{opacity: interpolatesStyles.opacity, left: interpolatesStyles.left}}>
                                    {props.children}
                                </div>
                                )}

            </Motion>
            );
};

const HeaderTextBar = (props) => {

    const {t} = useTranslation();
    return (<Motion
        defaultStyle={{scale: 0}}
        style={{
                    scale: spring(1, {stiffness: 390, damping: 50})
                }}
        >
        {interpolatesStyles => (
                        <div style={{transform: `scale(${interpolatesStyles.scale})`}}>
                            <span className="header-bar-text">{t(props.text)}</span>
                        </div>
                            )}

    </Motion>);
};

const Settings = (props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [lastActivePath] = props.pathData;

    const backHandler = () => {
        history.push(lastActivePath);
    };

    return (
            <div>
                <Switch>
                <Route exact path="/settings">
                    <Helmet>
                        <title>Fori: {t('settings')}</title>
                    </Helmet>
                    <div className="header-bar">
                        <span style={{width: 22}}></span>
                        <HeaderTextBar text="settings" />
                        <CloseIcon backFn={backHandler} />
                    </div>
                    <SettingsMenu/>
                </Route>
                <Route path="/settings/zipcodes">
                    <Helmet>
                        <title>Fori: {t('zipcodes')}</title>
                    </Helmet>
                    <div className="header-bar">
                        <div className="icon">
                            <Link to="/settings"><FontAwesomeIcon icon={faArrowLeft} className="2x"/></Link>
                        </div>
                        <HeaderTextBar text="zipcodes" />
                        <CloseIcon backFn={backHandler}/>
                    </div>
                    <MotionContainer>
                        <ZipCodes setzipcodes={props.setzipcodes} selectedzipcodes={props.selectedzipcodes}/>
                    </MotionContainer>
                </Route>
                <Route path="/settings/legal">
                    <Helmet>
                        <title>Fori: {t('legal')}</title>
                    </Helmet>
                    <div className="header-bar">
                        <div className="icon">
                            <Link to="/settings"><FontAwesomeIcon icon={faArrowLeft} className="2x"/></Link>
                        </div>
                        <HeaderTextBar text="legal" />
                        <CloseIcon backFn={backHandler}/>
                    </div>
                    <MotionContainer>
                        <Legal />
                    </MotionContainer>
                </Route>
                <Route path="/settings/language">
                    <Helmet>
                        <title>Fori: {t('myLang')}</title>
                    </Helmet>
                    <div className="header-bar">
                        <div className="icon">
                            <Link to="/settings"><FontAwesomeIcon icon={faArrowLeft} className="2x"/></Link>
                        </div>
                        <HeaderTextBar text="myLang" />
                        <CloseIcon backFn={backHandler}/>
                    </div>
                    <MotionContainer>
                        <Languages />
                    </MotionContainer>
                </Route>
                </Switch>

            </div>
            );
};

export default Settings
