import React, { useContext } from "react";
//import { useParams } from "react-router-dom";
//import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./StallItem.scss";

import UrlContext from "../../urlContext";

const StallItem = (props) => {
  const { baseUrl } = useContext(UrlContext);
  //const { venueId } = useParams();
  const { t } = useTranslation();
  //const history = useHistory();
  const { stall } = props;
  let categories = null;
  let stallName = null;
  let location = null;

  //set the stallname as the the name of the stall if stall.name is valid (not null, not '-' and not '')
  if (stall.name && stall.name !== "-" && stall.name !== "") {
    stallName = stall.name;
  } else if (stall.vendor && stall.vendor.companyname) {
    //set the stallname as the the company name of the stall if stall.vendor and stall.vendor.company name is not null
    stallName = stall.vendor.companyname;
  } else {
    //set the stall name as the last name of the vendor
    stallName = stall.vendor.lastname;
  }

  if (stall.merkecategories) {
    stall.merkecategories = stall.merkecategories.filter(
      (x) => x.name !== null
    );
    stall.merkecategories = stall.merkecategories.filter(
      (x) => x.name !== undefined
    );
    //no categories that start with 'categorie'
    stall.merkecategories = stall.merkecategories.filter(
      (x) => !x.name.toLowerCase().includes("categorie")
    );

    if (stall.merkecategories && stall.merkecategories.length > 0) {
      categories = stall.merkecategories
        .map((obj) => {
          let translation = t("categoryTypes." + obj.name.toLowerCase());
          if (translation.includes("categoryTypes.")) {
            return obj.name;
          } else {
            return translation;
          }
        })
        .join(", ");
    }
  }

  //set the location
  location = `${stall.location} `;

  //if the number isn't null add the number to location
  if (stall.locationNr && stall.location !== null) {
    location += `${stall.locationNr}`;
  }

  //return the stall item with the image, loaction and category
  return (
    <div className="stall-item">
      <div className="stall-info">
        <div className="stall-name">{stallName}</div>
      </div>
      <div className="stall-attraction">
        <div className="stall-attraction-container">
          {stall.pictureId && (
            <img
              className="stall-attraction-image"
              src={`${baseUrl}/files/read/${stall.pictureId}`}
              alt=""
            />
          )}
          <div className="stall-attraction-info">
            {stall.location !== null && (
              <div className="stall-location">
                {t("location")}{" "}
                <div className="stall-location-info">{location}</div>
              </div>
            )}
            {categories !== null && (
              <div
                className="stall-cat"
                style={stall.location ? { marginTop: 10 } : { marginTop: 0 }}
              >
                {stall.merkecategories.length > 1
                  ? t("categories")
                  : t("categorie")}
                <div className="stall-cat-info">{categories}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {
        // Tijdelijk disabled omdat geen extra info verschaft ... //Placeholder voor bonnen in de toekomst!
        /*<div className="stall-moreInfo" >
            <div className="stall-moreInfo-text" onClick={() => itemClickHandler()}>
                {t('moreInfo')}
            </div>
            <FontAwesomeIcon icon={faCaretRight} className="stall-moreInfo-icon" size="lg" onClick={() => itemClickHandler()} />
        </div>*/
      }
    </div>
  );
};

export default StallItem;
