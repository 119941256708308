import React, {useEffect, useState} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Motion, spring } from "@serprex/react-motion";
import {Helmet} from "react-helmet";

//blabla change in de files

import AnimatedView from "./components/animatedView/AnimatedView";

import Header from "./components/header/Header";
import Settings from "./components/settings/Settings";
import VenueList from "./components/venuelist/VenueList";
import Articles from "./components/articles/Articles";
import SliderMenu from "./components/slidermenu/SliderMenu";
import Button from "./components/button/Button";
import Intro from "./components/intro/Intro";
import ArticleDetail from "./components/articledetail/ArticleDetail";
import VenueDetail from "./components/venuedetail/VenueDetail";
import AttractionDetail from "./components/attractionDetail/AttractionDetail";

import SearchPage from "./containers/SearchPage"

import { withTranslation, useTranslation } from 'react-i18next';

import './Normalize.scss';
import './App.scss';

function App() {
    const [startAnimation, setStartAnimation] = useState(false);
    const [seenIntro, setSeenIntro] = useState(window.localStorage.getItem('seenIntro'));
    const [zipcodes, setZipCodes] = useState();
    const [venues, setVenues] = useState([]);
    const [articles, setArticles] = useState([]);
    const pathData = useState('');
    const defaultLanguage = window.localStorage.getItem('defaultLanguage');
    const { t } = useTranslation();

    useEffect(() => {
        if (defaultLanguage !== null) {
            let url = `/articles/${window.localStorage.getItem('defaultLanguage')}/articles.json`;
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(data => setArticles(data));
        }
    }, [defaultLanguage]);

    useEffect(() => {
        if (!seenIntro) {
            window.localStorage.removeItem('introSeen');
            window.localStorage.removeItem('postcodes');
            window.localStorage.removeItem('language');
        }
    }, [seenIntro]);

    useEffect(() => {
        //load in data from postcodes
        if (!window.localStorage.getItem('postcodes')) {
            window.localStorage.setItem('postcodes', JSON.stringify([]));
        }
        setZipCodes(() => JSON.parse(window.localStorage.getItem('postcodes')));
    }, []);

    useEffect(() => {
        //set postcodes on updates
        if (zipcodes !== null && typeof zipcodes !== 'undefined') {
            window.localStorage.setItem('postcodes', JSON.stringify(zipcodes));
        }
    }, [zipcodes]);

    useEffect(() => {
        if (typeof window.localStorage.defaultLanguage === 'undefined') {
            window.localStorage.setItem("defaultLanguage", "nl");
        }
    }, []);

    useEffect(() => {
        setStartAnimation(true);
    }, [startAnimation]);

    const hasSeenIntro = () => {
        if (seenIntro) {
            return (
                <div className="container clearfix">
                    <Switch>
                        <Route exact path="/">
                            <Helmet>
                                <title>Fori: {t('menuChoices.markets')}</title>
                            </Helmet>
                            <div className="sticky">
                                <Header pathData={pathData} />
                                <SliderMenu setStartAnimation={setStartAnimation} />
                            </div>
                            <AnimatedView startAnimation={startAnimation}>
                                <VenueList type="MARKET" zipcodes={zipcodes} venues={venues} setVenues={setVenues} pathData={pathData} />
                                <Button fixed to="/search" text="planMyVisit" />
                            </AnimatedView>
                        </Route>
                        <Route exact path="/fairs">
                            <Helmet>
                                <title>Fori: {t('menuChoices.fairs')}</title>
                            </Helmet>
                            <div className="sticky">
                                <Header pathData={pathData} />
                                <SliderMenu setStartAnimation={setStartAnimation} />
                            </div>
                            <AnimatedView startAnimation={startAnimation}>
                                <VenueList type="FAIR" zipcodes={zipcodes} venues={venues} setVenues={setVenues} pathData={pathData} />
                                <Button fixed to="/search" text="planMyVisit" />
                            </AnimatedView>
                        </Route>
                        <Route path="/info">
                            <Helmet>
                                <title>Fori: {t('menuChoices.info')}</title>
                            </Helmet>
                            <div className="sticky">
                                <Header pathData={pathData} />
                                <SliderMenu setStartAnimation={setStartAnimation} />
                            </div>
                            <AnimatedView startAnimation={startAnimation}>
                                <Articles articles={articles} />
                            </AnimatedView>
                        </Route>
                        <Route path="/articles/:articleId">
                            <Motion
                                defaultStyle={{ opacity: 0 }}
                                style={{
                                    opacity: spring(1, { stiffness: 105, damping: 25 })
                                }}>
                                {interpolatesStyles => (
                                    <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity }}>
                                        <ArticleDetail articles={articles} />
                                    </div>)}
                            </Motion>
                        </Route>
                        <Route path="/settings">
                            <Motion
                                defaultStyle={{ opacity: 0 }}
                                style={{ opacity: spring(1, { stiffness: 105, damping: 25 }) }}>
                                {interpolatesStyles => (
                                    <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity }}>
                                        <Settings pathData={pathData} setzipcodes={setZipCodes} selectedzipcodes={zipcodes} />
                                    </div>)}
                            </Motion>
                        </Route>
                        <Route path="/search">
                            <Motion
                                defaultStyle={{ opacity: 0 }}
                                style={{ opacity: spring(1, { stiffness: 105, damping: 25 }) }}>
                                {interpolatesStyles => (
                                    <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity }}>
                                        <SearchPage venues={venues} setVenues={setVenues} pathData={pathData} />
                                    </div>)}
                            </Motion>
                        </Route>
                        <Route path="/market/:venueId">
                            <Header pathData={pathData} />
                            <Motion
                                defaultStyle={{ opacity: 0 }}
                                style={{ opacity: spring(1, { stiffness: 105, damping: 25 }) }}>
                                {interpolatesStyles => (
                                    <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity }}>
                                        <VenueDetail venues={venues} pathData={pathData} />
                                    </div>)}
                            </Motion>
                        </Route>
                        <Route exact path="/fair/:venueId">
                            <Header pathData={pathData} />
                            <Motion
                                defaultStyle={{ opacity: 1 }}
                                style={{ opacity: spring(1, { stiffness: 105, damping: 25 }) }}>

                                {interpolatesStyles => (
                                    <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity }}>
                                        <VenueDetail venues={venues} pathData={pathData} />
                                    </div>)}
                            </Motion>
                        </Route>
                        <Route exact path="/fair/:venueId/:attractionId">
                            <Header pathData={pathData} />
                            <Motion
                                defaultStyle={{ opacity: 1 }}
                                style={{ opacity: spring(1, { stiffness: 105, damping: 25 }) }}>

                                {interpolatesStyles => (
                                    <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity }}>
                                        <AttractionDetail venues={venues} pathData={pathData} />
                                    </div>)}
                            </Motion>
                        </Route>
                        <Route path="/">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </div>
            );
        } else {
            return <Intro seenIntroFn={setSeenIntro} setzipcodes={setZipCodes} zipcodes={zipcodes} />;
        }
    };

    return (
        hasSeenIntro()
    );
}

export default withTranslation()(App);