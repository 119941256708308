import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const RemarkItem = (props) => {
    const {t} = useTranslation();
    const {type} = props;
    let text, extraCls = '';
    switch (type) {
        case "verified":
            extraCls = 'notverified';
            text = t('notVerified');
            break;
        case "cancellation":
            extraCls = 'cancelation-warning';
            text = t('cancelled');
            break;
        case "relocation":
            extraCls = 'relocation-warning';
            text = t('relocated');
            break;
        default:
            break;
    }

    return (
            <div className={`remark ${extraCls}`}>
                <FontAwesomeIcon className="remark-icon" icon= {faExclamationTriangle} />{text}
            </div>
            );
};

export default RemarkItem