import React from 'react';

import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {BrowserRouter as Router} from 'react-router-dom';
import {UrlContextProvider} from "./urlContext"

ReactDOM.render(
                <Router>
                    <UrlContextProvider>
                        <App />
                    </UrlContextProvider>
                </Router>,
                document.getElementById('root')
);

serviceWorkerRegistration.register();
