import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";

import UrlContext from "../../urlContext";

import "./AttractionDetail.scss";

const AttractionDetail = () => {
  const { venueId } = useParams();
  const { attractionId } = useParams();
  const history = useHistory();
  const [stall, setStall] = useState(null);
  const { baseUrl } = useContext(UrlContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [stallName, setStallName] = useState("");

  //Get all the attractions and set the attraction bassed on the attractionId
  useEffect(() => {
    let mounted = true;
    if (window.navigator.onLine) {
      var url = new URL(`${baseUrl}/fori/vendorstalldetail?venueId=${venueId}`);

      const options = {
        method: "GET",
        headers: new Headers({ Accept: "application/json" }),
      };
      setIsLoading(true);
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true && mounted) {
            data.data.map(
              (currentStall) =>
                currentStall.id === attractionId && setStall(currentStall)
            );
            setIsLoading(false);
          }
        });
    }

    //mounted exist so the data can not be set if you exit the page
    return () => (mounted = false);
  }, [venueId, attractionId, baseUrl]);

  //Set the category for the attraction
  //only used for Meifoor
  /*const mapCategory = (cat) => {
        switch (cat) {
            case "Grootvermaak":
            case "A-attracties":
            case "A":
            case "B":
            case "Opvullingsattractie":
                cat = "Attractie";
                break;
            default:
                break;
        }

        if (typeof cat === 'undefined') {
            cat = '';
        }

        return cat;
    };*/

  //set the name for the stall
  useEffect(() => {
    let mounted = true;
    if (stall && mounted) {
      //if stall name exist then that is the stallName
      if (stall.name && stall.name !== "-" && stall.name !== "") {
        setStallName(stall.name);
      } else {
        //if the stallname doesn't exist and the company name exist
        //then the company name becomes the stall name
        if (stall.vendor && stall.vendor.companyname) {
          setStallName(stall.vendor.companyname);
        } else {
          //if the stallname and the companyname does not exist then
          //the last name of the venor becomes the stallName
          setStallName(stall.vendor.lastname);
        }
      }
    }

    //mounted exist so the data can not be set if you exit the page
    return () => (mounted = false);
  }, [stall]);

  //display the location of the stall
  const renderStallLocation = () => {
    return (
      <div className="attraction-item-location">
        {t("location")}:
        <div className="attraction-item-location-info">
          {stall.location} {stall.locationNr}
        </div>
      </div>
    );
  };

  //render the category of the stall
  const renderStallCategorie = () => {
    //remove the category if the stallname is null or is undefined
    stall.merkecategories = stall.merkecategories.filter(
      (x) => x.name !== null
    );
    stall.merkecategories = stall.merkecategories.filter(
      (x) => x.name !== undefined
    );

    //wont display the stall category if there aren't any
    if (stall.merkecategories.length !== 0) {
      return (
        <div className="attraction-item-cat">
          {stall.merkecategories.length > 1
            ? t("categories") + ":"
            : t("categorie") + ":"}
          <div className="attraction-item-cat-info">
            {/*}{(stall.merkecategories.map(obj => (t("categoryTypes." + (venueId === "96e092fc-d653-11e6-9b24-615897ebd569" ? mapCategory(obj.name).toLowerCase() : obj.name.toLowerCase() )))).join(', '))}*/}
            {stall.merkecategories
              .map((obj) => t("categoryTypes." + obj.name.toLowerCase()))
              .join(", ")}
          </div>
        </div>
      );
    }
    return "";
  };

  //render the discription og the stall
  const renderStallDescription = () => {
    return (
      <div className="attraction-item-extraInfo">
        {t("extraInfo")}:
        <div className="attraction-item-extraInfo-info">
          {stall.description}
        </div>
      </div>
    );
  };

  //Go back to the previous page using the Id of the venue
  const handleBackClick = () => {
    history.push(`/fair/${venueId}`);
  };

  //Display the spinner if the info is loading
  const loader = () => {
    return (
      <div className="attraction-loading">
        <FontAwesomeIcon
          icon={faSpinner}
          className="attraction-loading-spinner"
        />
      </div>
    );
  };

  //display the attraction detail if the stall exist
  //display the location, category and the description if those exist
  //if the stall is loading display the loader
  //if the stall doesn't exist tell the stall doesn't exist
  return stall !== null ? (
    <div className="attraction-detail">
      <div className="attraction-detail-title">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBackClick}
          className="attraction-detail-title-arrow"
        />
        <div className="attraction-detail-title-text">{stallName}</div>
      </div>
      <div className="attraction-container">
        {stall.pictureId && (
          <img
            className="attraction-item-image"
            src={`${baseUrl}/files/read/${stall.pictureId}`}
            alt=""
          />
        )}
        <div className="attraction-item">
          {stall.location && renderStallLocation()}
          {stall.merkecategories && renderStallCategorie()}
        </div>
      </div>
      {stall.description && renderStallDescription()}
    </div>
  ) : isLoading ? (
    loader()
  ) : (
    <div className="attraction-detail">
      <div className="attraction-detail-title">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBackClick}
          className="attraction-detail-title-arrow"
        />
        <div className="attraction-detail-title-text">No stall found</div>
      </div>
    </div>
  );
};

export default AttractionDetail;
