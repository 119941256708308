import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";

const Location = (props) => {
  const { location, context, moved } = props;
  const address = `${location ? location + "," : ""}${
    context?.name ? context.name + "," : ""
  }${context?.zipcode ? context.zipcode + "," : ""}`.replace(/,$/, "");
  return (
    <span className={`location ${moved === true ? "crossed" : ""}`}>
      <FontAwesomeIcon icon={faMapMarker} className="location-icon" />
      <a
        rel="noreferrer"
        href={`http://maps.apple.com/?address=${address}`}
        target="_blank"
      >
        {location}
      </a>
    </span>
  );
};

export default Location;
